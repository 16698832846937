import { SanityRibbon } from "../../graphql-types";
import { Order } from "./useRibbon";

export default function calculateOrderTotal(
  order: Order[],
  ribbons: SanityRibbon[]
) {
  return order.reduce((runningTotal, singleOrder) => {
    const ribbon = ribbons.find((item) => item.id === singleOrder.id);
    return runningTotal + ribbon.price;
  }, 0);
}

import { SanityRibbon } from "../../graphql-types";
import formatMoney from "./formatMoney";
import { Order } from "./useRibbon";

export default function attachNamesAndPrice(
  order: Order[],
  ribbons: SanityRibbon[]
) {
  return order.map((item) => {
    const { name, image, price } = ribbons.find(
      (singleRibbon) => singleRibbon.id === item.id
    );
    return {
      ...item,
      name,
      price: formatMoney(price),
      thumbnail: image.asset.fluid.src,
    };
  });
}

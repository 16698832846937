import React, { useContext, useState } from "react";
import { SanityRibbon } from "../../graphql-types";
import formatMoney from "../utils/formatMoney";
import OrderContext from "../components/OrderContext";
import calculateOrderTotal from "./calculateOrderTotal";
import attachNamesAndPrice from "./attachNamesAndPrice";

interface IUseRibbon {
  ribbons: SanityRibbon[];
  values: {
    [key: string]: any;
  };
}

export interface Order {
  id: string;
}

export default function useRibbon({ ribbons, values }: IUseRibbon) {
  const [order, setOrder] = useContext(OrderContext);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  function addToOrder(orderedRibbon: Order) {
    setOrder([...order, orderedRibbon]);
  }

  function removeFromOrder(index: number) {
    setOrder([...order.slice(0, index), ...order.slice(index + 1)]);
  }

  async function submitOrder(e: React.ChangeEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setMessage(null);
    const body = {
      order: attachNamesAndPrice(order, ribbons),
      total: formatMoney(calculateOrderTotal(order, ribbons)),
      name: values.name,
      email: values.email,
      phone: values.phone,
      mapleSyrup: values.mapleSyrup,
    };

    const res = await fetch(
      `${process.env.GATSBY_SERVERLESS_BASE}/placeOrder`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    const text = JSON.parse(await res.text());

    if (res.status >= 400 && res.status < 600) {
      setLoading(false);
      setError(text.message);
    } else {
      setLoading(false);
      setMessage("Success! await for our contact. Thanks 🎀");
      setOrder([]);
    }
  }

  return {
    order,
    addToOrder,
    removeFromOrder,
    submitOrder,
    error,
    loading,
    message,
  };
}

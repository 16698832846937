import React, { useState } from "react";

interface IuseForm {
  [key: string]: any;
}

export default function useForm(defaults: IuseForm) {
  const [values, setValues] = useState(defaults);

  function updateValue(e: React.ChangeEvent<HTMLInputElement>) {
    let value: string | number = e.target.value;
    if (e.target.type === "number") {
      value = parseInt(value);
    }
    setValues({
      ...values,
      [e.target.name]: value,
    });
  }

  return { values, updateValue };
}

import React, { useState } from "react";
import { PageProps, graphql } from "gatsby";
import SEO from "../components/SEO";
import useForm from "../utils/useForm";
import Img from "gatsby-image";
import { AllRibbonsToOrderQuery, SanityRibbon } from "../../graphql-types";
import formatMoney from "../utils/formatMoney";
import OrderStyles from "../styles/OrderStyles";
import MenuItemStyles from "../styles/MenuItemStyles";
import useRibbon from "../utils/useRibbon";
import RibbonOrder from "../components/RibbonOrder";
import calculateOrderTotal from "../utils/calculateOrderTotal";

interface IOrderPage extends PageProps {
  data: AllRibbonsToOrderQuery;
}

export default function OrderPage({ data }: IOrderPage) {
  const ribbons = data.ribbons.nodes as SanityRibbon[];

  const { values, updateValue } = useForm({
    name: "",
    email: "",
    phone: "",
    mapleSyrup: "",
  });

  const {
    order,
    addToOrder,
    removeFromOrder,
    submitOrder,
    error,
    loading,
    message,
  } = useRibbon({
    ribbons,
    values,
  });

  if (message) {
    return <p>{message}</p>;
  }

  return (
    <>
      <SEO title="Order a Ribbon!!" />
      <OrderStyles onSubmit={submitOrder}>
        <fieldset disabled={loading}>
          <legend>Suas Informações</legend>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            value={values.name}
            onChange={updateValue}
          />
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            value={values.email}
            onChange={updateValue}
          />
          <label htmlFor="phone">Phone</label>
          <input
            type="tel"
            name="phone"
            id="phone"
            value={values.phone}
            onChange={updateValue}
          />
          <input
            type="mapleSyrup"
            name="mapleSyrup"
            id="mapleSyrup"
            className="mapleSyrup"
            value={values.mapleSyrup}
            onChange={updateValue}
          />
        </fieldset>
        <fieldset className="menu" disabled={loading}>
          <legend>Menu</legend>
          <div>
            {ribbons.map((ribbon) => (
              <MenuItemStyles key={ribbon.id}>
                <Img
                  width="50"
                  height="50"
                  alt={ribbon.name}
                  fluid={ribbon.image.asset.fluid}
                />
                <div>
                  <h2>{ribbon.name}</h2>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => addToOrder({ id: ribbon.id })}
                  >
                    Eu quero! {formatMoney(ribbon.price)}
                  </button>
                </div>
              </MenuItemStyles>
            ))}
          </div>
        </fieldset>
        <fieldset className="order" disabled={loading}>
          <legend>Order</legend>
          <RibbonOrder
            order={order}
            removeFromOrder={removeFromOrder}
            ribbons={ribbons}
          />
        </fieldset>
        <fieldset disabled={loading}>
          <h3>
            Your Total is {formatMoney(calculateOrderTotal(order, ribbons))}
          </h3>
          <div>{error ? <p>Error: {error}</p> : ""}</div>
          <button type="submit" disabled={loading}>
            {loading ? "Placing Order..." : "Order Ahead"}
          </button>
        </fieldset>
      </OrderStyles>
    </>
  );
}

export const query = graphql`
  query AllRibbonsToOrder {
    ribbons: allSanityRibbon {
      nodes {
        name
        id
        price
        slug {
          current
        }
        image {
          asset {
            fluid(maxWidth: 100) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;

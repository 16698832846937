import React from "react";
import { SanityRibbon } from "../../graphql-types";
import MenuItemStyles from "../styles/MenuItemStyles";
import { Order } from "../utils/useRibbon";
import Img from "gatsby-image";
import formatMoney from "../utils/formatMoney";

interface IRibbonOrder {
  order?: Order[];
  ribbons?: SanityRibbon[];
  removeFromOrder?: (index: number) => void;
}

export default function RibbonOrder({
  order,
  ribbons,
  removeFromOrder,
}: IRibbonOrder) {
  return (
    <>
      {order.map((singleOrder, index) => {
        const ribbon = ribbons.find((item) => item.id === singleOrder.id);
        return (
          <MenuItemStyles key={`${singleOrder.id}-${index}`}>
            <Img
              width="50"
              height="50"
              alt={ribbon.name}
              fluid={ribbon.image.asset.fluid}
            />
            <h2>{ribbon.name}</h2>
            <p>
              {formatMoney(ribbon.price)}
              <button
                type="button"
                className="remove"
                title={`Remove ${ribbon.name} from order`}
                onClick={() => removeFromOrder(index)}
              >
                &times;
              </button>
            </p>
          </MenuItemStyles>
        );
      })}
    </>
  );
}
